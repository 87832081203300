import React from "react";
import icon from "../../img/icon.svg";
import mainImage from "../../img/mainImage.svg";

function Main() {
  const handleEmailButtonClick = () => {
    window.location.href = "mailto:sales@emoney.tools";
  };

  return (
    <div className="main">
      <div className="container">
        <div className="main-inner">
          <img src={mainImage} className="main-logo" />
          <div className="main-body">
            <h1 class="main-title">Build your own products</h1>
            <h2 class="main-subtitle">
              using API integration or end-user solutions
            </h2>
            <h3 class="main-info">Payments orchestration platform</h3>
            <p class="main-info-text">
              Our technology is a critical competency for any high-growth or
              enterprise-scale merchant. It has direct impacts on operational
              efficiency as well as top- and bottom-line performance: a massive
              ecosystem of third-party payment services, rich, flexible
              reporting tools to help you spot opportunities for efficiency
              gains and customizable routing logic to increase acceptance rates,
              lower costs, and develop effective payment architecture across
              geographies.
            </p>
            <h3 class="main-info">Processing as a Service</h3>
            <p class="main-info-text">
              For startups from Central Asia and international companies
              entering this region, we provide a ready-made payment system
              integrated with local banks and pre-configured to operate under
              your agreements with local payment acceptance and issuance
              partners.
            </p>
            <button
              id="emailButton"
              className="main-button"
              onClick={handleEmailButtonClick}
            >
              <span className="button-text">Request more info</span>
              <span className="button-icon">
                <img src={icon} alt="Icon" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
