import React from "react";
import logo from "../../img/logo.svg";

function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="header-inner">
          <img src={logo} alt="Логотип" className="header-logo" />
          <div className="header-text">
            <h1 className="header-title">Financial backbone</h1>
            <h2 className="header-subtitle">
              for ambitious e-commerce of all sizes
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
